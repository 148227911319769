import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors from "@vertikal/e-prospera.styles.colors";
import Box from "@vertikal/e-prospera.ui.box";
import Link from "@vertikal/e-prospera.ui.link";
import Text from "@vertikal/e-prospera.ui.text";
import styled from "styled-components";
import Check from "../images/duotone-icons/icondone.svg";

export const MaxWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  padding: 1rem 1rem 3.5rem 1rem;
  @media ${breakpoints.md} {
    padding: 1.5rem 6rem 5rem 6rem;
  }
`;

export const BreadCrumbsWrapper = styled(Box)`
  position: relative;
  z-index: 1;
  color: ${colors.gray.c400};
  flex-wrap: wrap;
  div > p,
  div > div {
    margin-bottom: 0;
  }
`;

export const BreadCrumbLink = styled(Link)`
  user-select: none;
  font-weight: 600;
  span {
    font-weight: 600;
    text-decoration: none;
    color: ${colors.gray.c700};
  }
`;

export const BreadCrumbCurrent = styled(Text)`
  color: ${colors.gray.c600};
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

export const ServiceProviderContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 704px;
  margin: 0 auto;
  padding: 5.5rem 0 2rem 0;
`;

export const IframeWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 203px;
  @media ${breakpoints.sm} {
    height: 396px;
  }
`;

export const DescriptionList = styled.ul`
  list-style-image: url(${Check});
  padding-inline-start: 1rem;
  margin-top: 0;
  > li {
    margin: 0.5rem 0;
    padding-left: 0.25rem;
  }
`;

export const ServiceProviderLogo = styled.img`
  max-width: 300px;
`;

export const ServiceProviderNavigation = styled(Box)`
  flex-direction: column-reverse;
  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;
