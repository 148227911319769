import React from "react";
import { BreadCrumbsWrapper, HomeIcon } from "./Breadcrumbs.styles";
import useBreakpoints from "@vertikal/e-prospera.hooks.use-breakpoints";
import { Link as GatsbyLink } from "gatsby";

export const Breadcrumbs = ({ theme = "light", children }) => {
  const breakpoints = useBreakpoints();
  const getVisibleCrumbs = () => {
    if (breakpoints.lessThan.md) {
      return 2;
    } else if (breakpoints.lessThan.lg) {
      return 3;
    } else {
      return 4;
    }
  };
  const visibleCrumbs = getVisibleCrumbs();

  return (
    <BreadCrumbsWrapper $theme={theme}>
      {children.map((child, i, arr) => {
        if (children?.length > visibleCrumbs) {
          if (i === 0 || i >= children.length - visibleCrumbs + 1) {
            if (i === children.length - visibleCrumbs + 1) {
              return (
                <>
                  <span>/</span>
                  <span>...</span>
                  <span>/</span>
                  {
                    <div key={`breadcrumb-step-${i}`}>
                      {child}
                      {i !== arr.length - 1 && <span>/</span>}
                    </div>
                  }
                </>
              );
            } else {
              return (
                <div key={`breadcrumb-step-${i}`}>
                  {breakpoints.lessThan.md ? (
                    <GatsbyLink to={child.props?.to}>
                      <HomeIcon />
                    </GatsbyLink>
                  ) : (
                    child
                  )}
                </div>
              );
            }
          }
        } else {
          return (
            <div key={`breadcrumb-step-${i}`}>
              {child}
              {i !== arr.length - 1 && <span>/</span>}
            </div>
          );
        }
      })}
    </BreadCrumbsWrapper>
  );
};
