import colors from "@vertikal/e-prospera.styles.colors";
import styled from "styled-components";
import Icon from "@vertikal/e-prospera.ui.icon";

export const BreadCrumbsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  div {
    display: flex;
    gap: 0.5rem;
  }
  > div a,
  span {
    font-family: "Avenir Next";
    font-size: 1rem;
    font-weight: ${(props) =>
      props.$theme === "dark" ? 500 : colors.gray.c400};
    line-height: 1.5rem;
    letter-spacing: 0.32px;
    text-align: left;
    text-decoration: none;
    color: ${(props) =>
      props.$theme === "dark" ? colors.gray.c600 : colors.gray.c400};
  }
  > div:not(:last-of-type) a {
    color: ${(props) =>
      props.$theme === "dark" ? colors.gray.c700 : colors.gray.c200};
    font-weight: 600;
  }
`;

// apply styles only to the icon
export const HomeIcon = styled(Icon).attrs(() => ({
  name: "home",
}))`
  font-family: "Material Icons Round" !important;
  color: ${colors.gray.c700} !important;
`
